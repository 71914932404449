<template>
  <div class="page-users mb-4">
    <div class="row top-menu-users">
      <div class="mb-3 col-12 col-lg-auto">
        <search @search="handleSearch" size="lg"></search>
      </div>
      <div class="mb-3 col">
        <div class="row justify-content-end">
          <div class="col-lg-auto col-6">
            <button
              class="w-100 btn-sm btn btn-secondary"
              @click="openImportModal"
            >
              <img
                class="hide-dark"
                src="../assets/icons/icon-navigation-add.svg"
                alt=""
              />
              <img
                class="hide-light"
                src="../assets/icons/icon-navigation-add-light.svg"
                alt=""
              />
              <span>{{ $t("message.import") }}</span>
            </button>
          </div>
          <div class="col-lg-auto col-6">
            <button @click="handleAddUser" class="w-100 btn-sm btn btn-primary">
              <img
                class="hide-dark"
                src="../assets/icons/icon-navigation-add.svg"
                alt=""
              />
              <img
                class="hide-light"
                src="../assets/icons/icon-navigation-add-light.svg"
                alt=""
              />
              <span>{{ $t("message.user") }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--    <div class="row top-row mb-3">-->
    <!--      <div class="col-auto tree-filters row align-items-center flex-grow-1">-->
    <!--        <div class="col-auto d-flex">-->
    <!--          <search @search="handleSearch" size="lg"></search>-->
    <!--        </div>-->
    <!--        <div class="d-none col-auto">-->
    <!--          <input type="date" class="input-lg">-->
    <!--        </div>-->
    <!--        <div class="d-none col-auto">-->
    <!--          <button class="btn btn-primary btn-sm" @click.prevent.stop="openFilterModal">-->
    <!--            <img class="hide-dark" src="../assets/icons/icon-navigation-add.svg" alt="">-->
    <!--            <img class="hide-light" src="../assets/icons/icon-navigation-add-light.svg" alt=""> {{-->
    <!--              $t("message.filter")-->
    <!--            }}-->
    <!--          </button>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="ms-auto col-auto justify-content-end row tree-actions">-->
    <!--        <div class="col-auto">-->
    <!--          <button class="btn-sm btn btn-secondary" @click="openImportModal">-->
    <!--            <img class="hide-dark" src="../assets/icons/icon-navigation-add.svg" alt="">-->
    <!--            <img class="hide-light" src="../assets/icons/icon-navigation-add-light.svg" alt=""> {{-->
    <!--              $t("message.import")-->
    <!--            }}-->
    <!--          </button>-->
    <!--        </div>-->
    <!--        <div class="col-auto">-->
    <!--          <button @click="handleAddUser" class="btn-sm btn btn-primary">-->
    <!--            <img class="hide-dark" src="../assets/icons/icon-navigation-add.svg" alt="">-->
    <!--            <img class="hide-light" src="../assets/icons/icon-navigation-add-light.svg" alt=""> {{ $t("message.user") }}-->
    <!--          </button>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="row m-0 content-row d-flex boxshadow">
      <div
        class="p-0 col-xl-6 card flex-grow-1 rounded-start-0 boxshadow-0"
        :class="activeUser ? 'd-none d-xl-block' : ''"
      >
        <div class="card-header rounded-start-0 bgc-paars c-200">
          <span class="overline text-white text-sm ">
            {{ $t("message.users") }}
          </span>
        </div>
        <div class="card-body team-tree">
          <div class="company-tree-buttons actions mb-3">
            <!--      MOBILE BUTTONS      -->
            <div class="d-md-none">
              <div class="mb-2 btn-group w-100">
                <button
                  :class="userFilter === 'all' ? 'active' : ''"
                  @click.prevent="filterUsers('all')"
                  class="w-50 btn btn-sm btn-secondary"
                >
                  <span>{{ $t("message.all") }}</span>
                </button>
                <button
                  :class="userFilter === 'current' ? 'active' : ''"
                  @click.prevent="filterUsers('current')"
                  class="w-50 btn btn-sm btn-secondary"
                >
                  <span>{{ $t("message.current") }}</span>
                </button>
              </div>
              <div class="mb-2 btn-group w-100">
                <button
                  :class="userFilter === 'former' ? 'active' : ''"
                  @click.prevent="filterUsers('former')"
                  class="w-50 btn btn-sm btn-secondary"
                >
                  <span>{{ $t("message.former") }}</span>
                </button>
                <button
                  :class="userFilter === 'orphaned' ? 'active' : ''"
                  @click.prevent="filterUsers('orphaned')"
                  class="w-50 btn btn-sm btn-secondary"
                >
                  <span>{{ $t("message.orphaned") }}</span>
                </button>
              </div>
            </div>
            <!--      DESKTOP BUTTONS      -->
            <div class="d-none d-md-block">
              <div class="btn-group">
                <button
                  :class="userFilter === 'all' ? 'active' : ''"
                  @click.prevent="filterUsers('all')"
                  class="btn btn-sm btn-secondary"
                >
                  <span>{{ $t("message.all") }}</span>
                </button>
                <button
                  :class="userFilter === 'current' ? 'active' : ''"
                  @click.prevent="filterUsers('current')"
                  class="btn btn-sm btn-secondary"
                >
                  <span>{{ $t("message.current") }}</span>
                </button>
                <button
                  :class="userFilter === 'former' ? 'active' : ''"
                  @click.prevent="filterUsers('former')"
                  class="btn btn-sm btn-secondary"
                >
                  <span>{{ $t("message.former") }}</span>
                </button>
                <button
                  :class="userFilter === 'orphaned' ? 'active' : ''"
                  @click.prevent="filterUsers('orphaned')"
                  class="btn btn-sm btn-secondary"
                >
                  <span>{{ $t("message.orphaned") }}</span>
                </button>
              </div>
            </div>
          </div>
          <ul class="team">
            <li class="team-detail team-row branch-list active-branch">
              <div class="team-content d-flex align-items-center">
                <div class="team-name flex-grow-1">
                  <h5 class="mb-0">{{ mainCompany.title }}</h5>
                </div>
                <div class="actions d-flex">
                  <div
                    style="white-space: nowrap"
                    class="tree-action graph border-start px-2"
                  >
                    <img
                      src="../assets/icons/Icon-tree-user-profile.svg"
                      alt=""
                    />{{ totalRows }}
                  </div>
                </div>
              </div>
              <paginated
                @change="handlePageChange"
                :total-rows="totalRows"
                :per-page="10"
                :request="request"
              >
                <userlist
                  @saveUser="handleSaveUser"
                  @clickUser="handleClickUser"
                  :show-vitamins="false"
                  :users="users"
                ></userlist>
              </paginated>
            </li>
          </ul>
          <!--          <div class="team-actions flex-grow-1">-->
          <!--            <nav aria-label="Page navigation example" class="mt-3">-->
          <!--              <ul class="pagination justify-content-end">-->
          <!--                <li class="page-item">-->
          <!--                  <a class="btn mx-1" href="#" aria-label="Previous">-->
          <!--                    <span aria-hidden="true">-->
          <!--                      <img class="d-block img-fluid" src="../assets/icons/previous.svg" alt="">-->
          <!--                    </span>-->
          <!--                  </a>-->
          <!--                </li>-->
          <!--                <li class="page-item">-->
          <!--                  <a class="btn mx-1" href="#" aria-label="Next">-->
          <!--                    <span aria-hidden="true">-->
          <!--                      <img class="d-block img-fluid" src="../assets/icons/next.svg" alt="">-->
          <!--                    </span>-->
          <!--                  </a>-->
          <!--                </li>-->
          <!--              </ul>-->
          <!--            </nav>-->
          <!--          </div>-->
        </div>
      </div>
      <div v-if="activeUser" class="p-0 col-xl-6 card flex-grow-1 rounded-end-0 boxshadow-0">
        <div
          :class="activeUser ? '' : 'd-none d-xl-block'"
          class="card-header rounded-end-0 bgc-paars c-50"
        >
          <span class="overline text-sm text-white">
            <span :class="activeUser ? 'd-xl-none' : 'd-none'">
              <button
                class="btn btn-link p-0"
                @click.prevent="activeUser = null"
              >
                &lt; <span class="text-sentence">{{ $t("message.goBack") }}</span>
              </button>
            </span>
          </span>
        </div>
        <div class="card-body user-details border-start">
          <div class="row" v-if="activeUser">
            <!--            <pre>-->
            <!--              {{activeUser}}-->
            <!--            </pre>-->
            <div class="col-auto">
              <img
                style="height: 52px; width: 52px"
                src="../assets/icons/Icon-sidebar-Profile.svg"
                alt=""
              />
            </div>
            <div class="col">
              <ul class="list-group list-group-flush mb-3">
                <li class="list-group-item">
                  <img
                    style="padding: 4px"
                    class="me-2"
                    src="../assets/icons/Icon-sidebar-Profile.svg"
                    alt=""
                  />
                  {{ activeUser.firstname }} {{ activeUser.lastname }}
                </li>
                <!--                <li class="list-group-item">-->
                <!--                  <span class="d-inline-block spacer me-2" style="width: 24px;height: 24px;"></span>-->
                <!--                  -->
                <!--                </li>-->
                <li v-if="activeUser.gender" class="list-group-item">
                  <img
                    class="me-2"
                    src="../assets/icons/icon-user-gender.svg"
                    alt=""
                  />
                  <!--TODO: Change to list option-->
                  {{ getTranslation(activeUser.gender, 'gender') }}
                </li>
                <li v-if="activeUser.email" class="list-group-item">
                  <img
                    class="me-2"
                    src="../assets/icons/icon-user-mail.svg"
                    alt=""
                  />
                  {{ activeUser.email }}
                </li>
                <li v-if="activeUser.phone" class="list-group-item">
                  <img
                    class="me-2"
                    src="../assets/icons/icon-user-phone.svg"
                    alt=""
                  />
                  {{ activeUser.phone }}
                </li>
              </ul>
              <ul class="list-group list-group-flush">
                <li v-if="activeUser.jobtitle" class="list-group-item">
                  <img
                    class="me-2"
                    src="../assets/icons/icon-user-job-title.svg"
                    alt=""
                  />
                  <span class="text-sentence">{{ getTranslation(activeUser.jobtitle, 'jobtitle') }}</span>
                </li>
                <li
                  v-if="userTeams.length"
                  class="list-group-item d-flex align-items-start"
                >
                  <img
                    class="me-2"
                    src="../assets/icons/icon-user-team.svg"
                    alt=""
                  />
                  <ul>
                    <li v-bind:key="team" v-for="team in userTeams">
                      <span class="text-sentence">{{ team }}</span>
                    </li>
                  </ul>
                </li>
                <li
                  style="min-height: 75px"
                  class="
                    list-group-item
                    d-flex
                    align-items-start
                    position-relative
                  "
                  v-else-if="!teamsLoaded"
                >
                  <div
                    class="
                      position-absolute
                      top-50
                      start-50
                      translate-middle
                      container-spinner
                    "
                  >
                    <div class="spinner-border" role="status">
                      <span class="visually-hidden"
                        >{{ $t("message.loading") }}...</span
                      >
                    </div>
                  </div>
                </li>
                <li
                  v-if="userTeamsManaged.length"
                  class="list-group-item d-flex align-items-start"
                >
                  <img
                    class="me-2"
                    src="../assets/icons/Icon-tree-manager-profile.svg"
                    alt=""
                  />
                  <ul>
                    <li v-bind:key="team" v-for="team in userTeamsManaged">
                      <span class="text-sentence">{{ team }}</span>
                    </li>
                  </ul>
                </li>
                <li
                  style="min-height: 75px"
                  class="
                    list-group-item
                    d-flex
                    align-items-start
                    position-relative
                  "
                  v-else-if="!managedLoaded"
                >
                  <div
                    class="
                      position-absolute
                      top-50
                      start-50
                      translate-middle
                      container-spinner
                    "
                  >
                    <div class="spinner-border" role="status">
                      <span class="visually-hidden"
                        >{{ $t("message.loading") }}...</span
                      >
                    </div>
                  </div>
                </li>
                <!--                <li class="list-group-item">-->
                <!--                  <img style="padding: 4px;" class="me-2" src="../assets/icons/atblue.svg" alt="">-->
                <!--                  {{ activeUser.managermail }}-->
                <!--                </li>-->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Userlist from "@/views/components/Userlist";
import Search from "@/views/components/simple/Search";
import UserModal from "@/views/components/modals/UserModal";
import Paginated from "@/views/components/simple/Paginated";
import UserImport from "@/views/components/UserImport";
import qs from "qs";
import moment from "moment";

export default {
  components: { Paginated, Search, Userlist },
  created() {
    this.getUsers();
    this.getListOptions();
  },
  // mounted() {
  //   this.activeUser = this.users[0]
  //   this.getTeamsManagerOf()
  //   this.getTeamsMemberOf()
  // },
  methods: {
    filterUsers(type) {
      this.userFilter = type;
      this.getUsers();
    },
    handleSaveUser(user) {
      const current = this.$store.getters.getUser;
      if (current.id === user.id) {
        this.$store.commit("setUser", {
          user: { ...current, ...user },
        });
        localStorage.setItem("user", JSON.stringify(user));
      }
      this.getUsers();
    },
    handleAddUser() {
      this.showUserPopup({});
    },
    showUserPopup(user) {
      this.$store.dispatch("openModal", {
        component: UserModal,
        properties: {
          userNode: user,
          type: "settings",
        },

        options: {
          size: "xl",
          centered: false,
        },
        events: {
          userSaved: this.handleSaveUser,
        },
      });
    },
    openImportModal() {
      this.$store.dispatch("openModal", {
        component: UserImport,
        properties: {},
        options: {
          size: "xl",
        },
        events: {
          importedUsers: this.handleSaveUser,
        },
      });
    },
    handleSearch(value) {
      this.search = value;
      this.getUsers();
    },
    handleClickUser(user) {
      // console.log('clicked user', user)
      this.activeUser = user;
      this.getTeamsManagerOf();
      this.getTeamsMemberOf();
    },
    /*
     * Get all users based on filters and search.
     * Uses this.userFilter and this.search
     */
    async getUsers() {
      let query = "";
      let filter = "";
      let qscurrent = {
        _where: {
          _or: [
            {
              employmentend_gt: moment().format("YYYY-MM-DD"),
            },
            {
              employmentend_null: true,
            },
            {
              isdeleted_ne: true
            },
            {
              isdeleted_null: true
            }
          ],
        },
      };
      if (this.userFilter !== "all") {
        //current/former/orphaned
        switch (this.userFilter) {
          case "current":
            // filter = 'employmentend_gt=' + moment().format('YYYY-MM-DD')
            filter = qscurrent;
            break;
          case "former":
            filter = qs.stringify({
              _where: {
                employmentend_lte: moment().format("YYYY-MM-DD"),
                _or: [
                  {isdeleted_ne: true},
                  {
                    isdeleted_null: true
                  }
                ]
              }
            });
            break;
          case "orphaned":
            filter = qs.stringify({
              _where: {
                member_null: true,
                _or: [
                  {isdeleted_ne: true},
                  {
                    isdeleted_null: true
                  }
                ]
              }
            });
            break;
          default:
            break;
        }
      }
      if (this.search) {
        /*
         * If the filter is "current", create a new query object
         * Because of the way Strapi handles these, we can only add AND inside OR, not the other way around
         * So for every filter we need an OR, and for each of those we need an AND for both fields, this creates 4 arrays inside the OR object
         */
        if (this.userFilter === "current") {
          const filters = filter["_where"]["_or"];
          filter["_where"]["_or"] = [];
          for (const query of filters) {
            const search = [
              { firstname_contains: this.search },
              { lastname_contains: this.search },
            ];
            for (const term of search) {
              filter["_where"]["_or"].push([query, term]);
            }
          }
          filter = "?" + qs.stringify(filter);
        } else {
          query =
            "?" +
            qs.stringify({
              _where: {
                _or: [
                  { firstname_contains: this.search },
                  { lastname_contains: this.search },
                ],
              },
            });
          filter = "&" + filter;
        }
      } else {
        //* If there is no search, stringify the filters if the userfilter is current
        if (this.userFilter == "current") filter = qs.stringify(filter);
        filter = "?" + filter;
      }
      this.request = "/users" + query + filter;
      this.totalRows = 0;
      const get = await this.axios.get("/users/count" + query + filter);
      // const get = await this.axios.get('/users/count' + query + filter + '&_limit=-1')
      this.totalRows = get.data;
    },
    async getTeamsMemberOf() {
      if (this.activeUser) {
        this.activeUserMemberTeams = [];
        this.teamsLoaded = false;
        const get = await this.axios.get(
          "/customuser/getteams/" + this.activeUser.id
        );
        this.activeUserMemberTeams = get.data;
        this.teamsLoaded = true;
      }
    },
    async getTeamsManagerOf() {
      if (this.activeUser) {
        this.activeUserManagedTeams = [];
        this.managedLoaded = false;
        const get = await this.axios.get(
          "/customuser/getmanaged/" + this.activeUser.id
        );
        this.activeUserManagedTeams = get.data;
        this.managedLoaded = true;
      }
    },
    handlePageChange(users) {
      this.users = users;
      if(window.outerWidth > 1200){
        this.activeUser = this.users[0];
      }
      this.getTeamsManagerOf();
      this.getTeamsMemberOf();
      this.mitt.emit("loadcomplete", { complete: true });
    },
    async getListOptions() {
      const { data: options } = await this.axios.get("/settings/fieldoptions");
      this.listOptions = options;
    },
    getTranslation(key, type) {
      if (this.listOptions) {
        let value = this.listOptions.localized[this.$i18n.locale].listtranslations[key.toLowerCase()];
        if (!value) {
          const obj = this.listOptions.listoptions.find(list => list.key == type.toLowerCase());
          if (obj) {
            let option = obj.options.find(option => option.key == key.toLowerCase());
            return option ? option.value : key;
          }
          return key;
        }

        return value
      }
      return key;
    }
  },
  computed: {
    filteredUsers() {
      return this.users.filter((user) => {
        return (
          user.firstname.toUpperCase().indexOf(this.search.toUpperCase()) >
            -1 ||
          user.lastname.toUpperCase().indexOf(this.search.toUpperCase()) > -1
        );
      });
    },
    userTeams() {
      let teams = [];
      if (this.activeUser) {
        // teams = this.activeUser.member.filter(
        teams = this.activeUserMemberTeams
          .filter((swarm) => {
            return swarm.issegment !== true;
          })
          .map((swarm) => {
            return swarm.title;
          });
      }
      return teams;
    },
    userTeamsManaged() {
      let teams = [];
      if (this.activeUser) {
        // teams = this.activeUser.swarms.filter(
        teams = this.activeUserManagedTeams
          .filter((swarm) => {
            return swarm.issegment !== true;
          })
          .map((swarm) => {
            return swarm.title;
          });
      }
      return teams;
    },
  },
  data() {
    return {
      userFilter: "current",
      teamsLoaded: false,
      managedLoaded: false,
      totalRows: 0,
      request: "",
      search: "",
      activeUser: null,
      activeUserManagedTeams: [],
      activeUserMemberTeams: [],
      users: [],
      listOptions: null,
      company: {
        // title: this.mainCompany.title
      },
    };
  },
};
</script>