<template>
  <ul class="team tree-children tree-ul">
    <li class="cursor-pointer user team team-row" v-for="u in users" v-bind:key="u.id">
      <!--      {{ activeUser.id }}-->
      <div :class="activeUser.id === u.id ? 'active' : ''" class="team-content d-flex border-top"
           @click="clickUser(u)">
        <div class="team-name flex-grow-1">{{ u.firstname }} {{ u.lastname }}</div>
        <span v-if="(!u.member || u.member.length === 0) && $route.name === 'users' && employed(u)"
              class="ms-auto me-2 tip tip-orphan">
             <span class="text-sm tip-content text-sentence">
              {{ $t('message.orphan_user_tooltip') }}
            </span>
             <i class="c-koraalrood c-200 fas fa-fw fa-exclamation-triangle"></i>
        </span>
        <span v-if="!employed(u)"
              class="ms-auto me-2 tip tip-orphan">
             <span class="text-sentence text-sm tip-content text-sentence">
               {{ $t('message.no_longer_employed_since') }} {{ u.employmentend }}
            </span>
             <i class="c-okergeel c-200 far fa-fw fa-calendar-times"></i>
        </span>
        <div class="actions d-flex">

          <div class="spacer" style="min-height: 34px">&nbsp;</div>
          <div v-if="showVitamins && u.hasVitamins" class="tree-action users border-end px-2"
               @click.stop.prevent="showSubmissionPopup(u)">
            <img src="../../assets/icons/Icon-tree-user-vitamins.svg" alt="">
          </div>
          <div v-if="user.manageusers ||user.managecompany" class="cursor-pointer tree-action graph  px-2"
               @click.stop.prevent="showUserPopup(u)">
            <img src="../../assets/icons/Icon-tree-user-edits.svg" alt="">
          </div>
          <div v-if="team && !issegment" class="tree-action settings p-2 c-koraalrood c-200" @click="handleRemove(u)">
            <i class=" far fa-trash-alt"></i>
          </div>

        </div>
      </div>
    </li>
  </ul>
</template>

<script>

import UserModal from "@/views/components/modals/UserModal";
import SubmissionModal from "@/views/components/modals/SubmissionModal";
import ConfirmDelete from "@/views/components/modals/ConfirmDelete";
import moment from 'moment'

export default {
  data() {
    return {
      activeUser: {},
      // vitaminslist: {},
    }
  },
  props: ['users', 'showVitamins', 'team', 'typeList', 'issegment'],
  async mounted() {
    // for (const user of this.users) {
    //   const {data} = await this.axios.get('/checkupsubmissions/count?authorid=' + user.id + '&checkupentry.checkup.swarms_contains=' + this.team);
    //   this.vitaminslist[user.id] = data > 0;
    // }
  },
  watch: {
    // users() {
    //   if(window.offsetWidth > 767){
    //     this.activeUser = this.users[0]
    //   }
    //
    // }
  },
  methods: {
    employed(usr) {
      return !(usr.employmentend && moment(usr.employmentend).isBefore(moment()));
    },
    async confirmRemove(id) {
      const {data: team} = await this.axios.get('/swarms/' + this.team)
      let savedTeam;
      if (this.typeList === 'users') {
        const cleansedZerglings = team.zerglings.filter((member) => {
          return member.id !== id
        })
        savedTeam = await this.axios.put('/swarms/' + this.team, {
          zerglings: cleansedZerglings
        })
      } else {
        const cleansedOverlords = team.overlords.filter((member) => {
          return member.id !== id
        })
        savedTeam = await this.axios.put('/swarms/' + this.team, {
          overlords: cleansedOverlords
        })
      }

      this.$emit('reloadList', savedTeam.data)
    },
    async handleRemove(user) {
      this.$store.dispatch('openModal', {
        component: ConfirmDelete,
        properties: {
          title: user.firstname + ' ' + user.lastname,
          id: user.id,
        },
        options: {
          size: 'xl',
          centered: false
        },
        events: {
          submitModal: this.confirmRemove
        }
      })
      //remove uiser from team
    },
    handleSubmit(user) {
      this.$emit('saveUser', user);
    },
    clickUser(user) {
      this.activeUser = user;
      this.$emit('clickUser', user);
    },
    showUserPopup(user) {
      this.$store.dispatch('openModal', {
        component: UserModal,
        properties: {
          userNode: user,
          type: 'settings',
        },
        options: {
          size: 'xl',
          centered: false
        },
        events: {
          userSaved: this.handleSubmit
        }
      })
    },
    showSubmissionPopup(user) {
      this.$store.dispatch('openModal', {
        component: SubmissionModal,
        properties: {userid: user.id, teamid: this.team},
        options: {
          size: 'lg',
        },
      })
    },
  }
}
</script>