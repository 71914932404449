<template>
  <div class="modal-content rounded-16 overflow-hidden">
    <div class="modal-header bgc-paars c-200 text-white">
      <span class="text-sentence">{{ $t("message.import_users") }}</span>
      <button
        type="button"
        class="d-block ms-auto btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
      ></button>
    </div>
    <div class="modal-body">
      <div class="d-flex justify-content-end">
        <label class="d-block mb-0 text-sentence" for="userfile">
          <input
            id="userfile"
            name="userfile"
            @input="resetFileInput"
            @change="readFile($event)"
            type="file"
            class="d-none btn btn-secondary styled-input"
          />
          <span class="d-block btn btn-secondary me-2"
            ><i class="fas fa-upload"></i>&nbsp;{{ computedFilename }}</span
          >
        </label>
        <a
          href="/vitamins_usertemplate.csv"
          download
          class="btn btn-secondary me-2"
        >
          <i class="fas fa-download"></i>
          <span>{{ $t("message.download_template") }}</span>
        </a>
        <button class="btn btn-secondary" @click="createOptionsCSV">
          <i class="fas fa-download"></i>
          <span>{{ $t("message.download_import_keys") }}</span>
        </button>
      </div>
      <div class="row">
        <!--        <form v-if="!showPostProcess" class="col-md-12 mb-3">-->
        <!--          <div class="form-row mb-2">-->
        <!--            <div class="col-md-12">-->
        <!--              -->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </form>-->
        <div class="results col-12">
          <div class="row">
            <div
              class="
                col-md-12
                import-preview
                preview
                position-relative
                invalid
                mb-5
              "
              v-if="invalidRows.length"
            >
              <h3 class="mb-3 text-sentence">
                {{ $t("message.x_invalid_rows", { amt: invalidRows.length }) }}
              </h3>
              <ul
                class="
                  list-style-none
                  small
                  text-danger
                  m-0
                  p-0
                  import_error_field_list
                "
              >
                <li class="p-0 mb-1" v-for="(error, i) in errorFields" :key="i">
                  {{ error }}
                </li>
              </ul>
              <div style="overflow-x: auto">
                <table class="no-p mt-0 table table-striped">
                  <thead class="bgc-koraalrood c-50">
                    <tr>
                      <th v-for="(column, i) in columns" :key="i">
                        <span class="text-sentence d-table-cell">{{
                          $t("message." + column)
                        }}</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(record, i) in invalidRows" :key="i">
                      <td
                        v-for="(column, key) in filteredRecord(record)"
                        :key="key"
                        :class="
                          record.invalidFields.includes(key)
                            ? 'text-danger'
                            : ''
                        "
                      >
                        <template
                          v-if="
                            key !== 'fullname' &&
                            key !== 'username' &&
                            key !== 'password' &&
                            key !== 'employmentstart' &&
                            key !== 'employmentend' &&
                            key !== 'birthdate' &&
                            key !== 'country' &&
                            key !== 'invalidFields' &&
                            key !== 'rowIndex' &&
                            key !== 'teams' &&
                            key !== 'tags'
                          "
                        >
                          {{ column }}
                        </template>
                        <template
                          v-else-if="
                            key === 'employmentstart' ||
                            key === 'employmentend' ||
                            key === 'birthdate'
                          "
                        >
                          {{
                            /*formatDate(column)*/
                            column ? moment(column).format("DD/MM/YYYY") : ""
                          }}
                        </template>
                        <template v-else-if="key === 'country'">
                          <span class="text-sentence">{{
                            $t("message.country_" + column)
                          }}</span>
                        </template>
                        <template v-else-if="key === 'teams' || key === 'tags'">
                          {{ column.join(", ") }}
                        </template>
                        <template v-else>{{ key }}</template>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-md-12 ready" v-if="results">
              <div class="importdone" v-if="showPostProcess">
                <p class="small text-sentence d-block">
                  {{ $t("message.x_records_processed", { amt: processed }) }}
                </p>
                <p class="small text-sentence d-block">
                  {{
                    $t("message.x_users_imported_successfully", {
                      amt: successRecords,
                    })
                  }}
                </p>
                <p class="small text-sentence d-block">
                  {{
                    $t("message.x_users_could_not_be_imported", {
                      amt: errorRecords,
                    })
                  }}
                </p>
                <div class="errors-import" v-if="errors.length">
                  <h4 class="text-sentence d-block">
                    {{ $t("message.the_following_errors_occurred") }}
                  </h4>
                  <div
                    class="error"
                    v-for="(error, i) in orderedErrors"
                    :key="i"
                  >
                    <span class="text-sentence d-block"
                      >{{ $t("message.row") }} {{ error.row }} &ndash;</span
                    >
                    <span
                      v-for="(msg, i) in error.error.response.data.data[0]
                        .messages"
                      :key="i"
                    >
                      <span class="field" v-if="msg.field"
                        >{{ error.record[msg.field] }}:</span
                      >
                      {{ msg.message }}
                    </span>
                  </div>
                </div>
                <div class="actions mt-5 text-center">
                  <button
                    class="button btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    <span>{{ $t("message.close") }}</span>
                  </button>
                </div>
              </div>
              <div
                class="import-preview preview position-relative"
                v-if="firstresults.length"
              >
                <h3 class="mb-3 text-sentence d-block">
                  {{
                    $t("message.first_x_records", { amt: firstresults.length })
                  }}
                </h3>
                <div style="overflow-x: auto">
                  <table class="table table-striped">
                    <thead class="bgc-paars c-50">
                      <tr>
                        <th
                          v-for="(column, i) in columns"
                          :key="i"
                          class="text-nowrap text-sentence d-table-cell"
                        >
                          {{ $t("message." + column) }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(record, i) in firstresults" :key="i">
                        <td
                          v-for="(column, key) in filteredRecord(record)"
                          :key="key"
                        >
                          <template
                            v-if="
                              key !== 'fullname' &&
                              key !== 'username' &&
                              key !== 'password' &&
                              key !== 'employmentstart' &&
                              key !== 'employmentend' &&
                              key !== 'birthdate' &&
                              key !== 'country' &&
                              key !== 'invalidFields' &&
                              key !== 'rowIndex' &&
                              key !== 'teams' &&
                              key !== 'tags'
                            "
                          >
                            {{ column }}
                          </template>
                          <template
                            v-else-if="
                              key === 'employmentstart' ||
                              key === 'employmentend' ||
                              key === 'birthdate'
                            "
                          >
                            {{
                              /*formatDate(column)*/
                              column ? moment(column).format("DD/MM/YYYY") : ""
                            }}
                          </template>
                          <template v-else-if="key === 'country'">
                            <span class="text-sentence">{{
                              $t("message.country_" + column)
                            }}</span>
                          </template>
                          <template
                            v-else-if="key === 'teams' || key === 'tags'"
                          >
                            {{ column.join(", ") }}
                          </template>
                          <template v-else>{{ key }}</template>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <transition name="loadfade">
                  <div
                    class="
                      d-flex
                      align-items-center
                      justify-content-center
                      loadingscreen
                      position-absolute
                    "
                    v-if="processing"
                  >
                    <div
                      class="
                        loadingbar
                        d-flex
                        align-items-center
                        justify-content-center
                        position-relative
                      "
                    >
                      <div class="complete-pct text-white position-absolute">
                        {{ pctComplete }}%
                      </div>
                      <div
                        :class="
                          parseInt(pctComplete) >= parseInt(pct * 10)
                            ? 'done'
                            : 'not-done'
                        "
                        class="loading-block"
                        v-for="pct in 10"
                        :key="pct"
                      ></div>
                    </div>
                  </div>
                </transition>
              </div>

              <div class="actions mt-5">
                <button
                  v-if="showImportButton"
                  @click.prevent="handleImport($event)"
                  type="button"
                  class="w-100 btn btn-primary"
                >
                  <div
                    style="height: 12px; width: 12px"
                    v-if="processing"
                    class="spinner-border"
                    role="status"
                  >
                    <span class="visually-hidden"
                      >{{ $t("message.loading") }}...</span
                    >
                  </div>
                  <span v-else>{{ $t("message.import_users") }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";
import axios from "axios";

export default {
  data() {
    return {
      countries: [],
      filename: "",
      array: [],
      reader: new FileReader(),
      results: [],
      currentUser: this.$store.getters.getUser,
      errors: [],
      listOptions: null,
      successRecords: 0,
      errorRecords: 0,
      showPostProcess: false,
      showErrors: false,
      invalidRows: [],
      processing: false,
      processed: 0,
      columns: [
        "lastname",
        "firstname",
        "email",
        "gender",
        "birthdate",
        "maritalstate",
        "street",
        "number",
        "bus",
        "postalcode",
        "city",
        "stateprovince",
        "country",
        "language",
        "jobtitle",
        "seniority",
        "employmentstart",
        "employmentend",
        "terminationreason",
        "education",
        "transportation",
        "children",
        "teams",
        "tags",
      ],
      file: null,
      moment: moment,
    };
  },
  computed: {
    errorFields() {
      let errors = {};
      let errorMessages = {
        lastname: this.$t("") + " " + this.$t("message.cannot_be_empty"),
        firstname: this.$t("") + " " + this.$t("message.cannot_be_empty"),
        email:
          this.$t("") +
          " " +
          this.$t("message.cannot_be_empty_or_must_be_unique"),
        birthdate: this.$t("message.must_be_dmyyyy_or_iso"),
        employmentstart: this.$t("message.must_be_dmyyyy_or_iso"),
        employmentend: this.$t("message.must_be_dmyyyy_or_iso"),
        language: this.$t("message.field_value_restrictions", {
          values: ["en", "nl-BE", "fr-FR"].join(", "),
        }),
        gender: this.$t("message.field_value_restrictions", {
          values: ["m", "f", "x"].join(", "),
        }),
        education: this.$t("message.field_value_restrictions", {
          values: this.listOptions
            .find((list) => list.key == "education")
            .options.map((option) => option.key)
            .join(", "),
        }),
        transportation: this.$t("message.field_value_restrictions", {
          values: this.listOptions
            .find((list) => list.key == "transport")
            .options.map((option) => option.key)
            .join(", "),
        }),
        maritalstate: this.$t("message.field_value_restrictions", {
          values: this.listOptions
            .find((list) => list.key == "maritalstate")
            .options.map((option) => option.key)
            .join(", "),
        }),
        country: this.$t("message.invalid_country"),
      };
      this.invalidRows.forEach((invalidUser) => {
        invalidUser.invalidFields.forEach((field) => {
          errors[field] =
            "[" + this.$t("message." + field) + "]: " + errorMessages[field];
        });
      });
      return errors;
    },
    computedFilename() {
      return this.filename
        ? this.filename
        : this.$t("message.click_to_add_csv");
    },
    showImportButton() {
      let show = false;
      if (this.results.length) {
        show = true;
      }
      return show;
    },
    orderedErrors() {
      return _.orderBy(this.errors, "row");
    },
    firstresults() {
      return this.results.slice(0, 10);
    },
    pctComplete() {
      return ((this.processed / this.results.length) * 100).toFixed(0);
    },
    /*      pctComplete() {
            return (this.processed / this.results.length) * 100).toFixed();
          }*/
  },
  created() {
    this.reader.onload = (e) => {
      let result = this.reader.result;
      if (result.match(/�/)) {
        this.reader.readAsText(this.file, "windows-1252");
      } else {
        this.parseFile(e);
      }
    };
    this.getCountries();
    this.getListOptions();
  },
  methods: {
    filteredRecord(record) {
      let filtered = { ...record };
      delete filtered.rowIndex;
      delete filtered.invalidFields;
      delete filtered.password;
      delete filtered.fullname;
      delete filtered.username;
      return filtered;
    },
    async getListOptions() {
      const { data: options } = await this.axios.get("/settings/fieldoptions");
      this.listOptions = options.listoptions;
    },
    async getCountries() {
      const f = await axios.get("/countries.json");
      this.countries = f.data;
    },
    closePostProcess() {
      this.results = [];
      this.errors = [];
      this.successRecords = 0;
      this.errorRecords = 0;
      this.showPostProcess = false;
      this.showErrors = false;
      this.invalidRows = [];
      this.processing = false;
      this.processed = 0;
      this.file = null;
    },
    randomPassword(length = 12) {
      const chars = "0123456789abcdefghijklmnopqrstuvwxyz";
      let pwa = [];
      for (let i = 0; i < length; i++) {
        let char = chars.charAt(Math.round(Math.random() * (chars.length - 1)));
        pwa.push(char);
      }
      let pws = pwa.join("");
      return pws;
    },
    async handleImport(e) {
      if (!this.processing) {
        let el = e.target;
        el.classList.add("loading");
        /*const importing = */
        await this.importValidUsers();
        // this.loadingSuccess(el);
        this.showPostProcess = true;
        this.results = [];
        this.invalidRows = [];
        this.$emit("importedUsers");
      }
    },
    async importValidUsers() {
      this.processing = true;
      this.processed = 0;
      this.successRecords = 0;
      this.errorRecords = 0;

      console.log(this.results);

      for (const user of this.results) {
        user["provider"] = "local";
        user["role"] = 1;
        // console.log("test 2");
      }
      // console.log("test");

      const response = await this.axios.post(
        "/customuser/import",
        this.results
      );
      console.log(response);
      const data = response.data;
      this.processed = data.processed;
      this.successRecords = data.successCount;
      this.errorRecords = data.errorCount;
      this.errors = data.errors;
      // let promises = this.results.map((result/*, resultIndex*/) => {
      //   result['provider'] = 'local';
      //   result['role'] = 1;
      //   return this.axios.post('/customuser/import', result).then(() => {
      //     this.processed++;
      //     this.successRecords++;
      //   }).catch(r => {
      //     console.error('catch error', [result, r]);
      //     this.processed++;
      //     this.errorRecords++;
      //     this.errors.push({
      //       row: result.rowIndex,
      //       error: r,
      //       record: result
      //     })
      //   });
      // });
      //
      // return Promise.all(promises)
    },
    validateUser(user, users) {
      let invalidFields = [];
      let valid = true;

      let uniqueFields = ["email"];

      let requiredFields = ["email", "firstname", "lastname"];

      let dateFields = ["birthdate", "employmentstart", "employmentend"];

      requiredFields.forEach((field) => {
        if (!user[field]) {
          valid = false;
          invalidFields.push(field);
          user[field] = this.$t("message.$ERR_REQ");
        }
      });

      uniqueFields.forEach((field) => {
        if (!invalidFields.includes(field)) {
          const exists = users.find((item) => item[field] == user[field]);

          if (exists) {
            valid = false;
            invalidFields.push(field);
            user[field] =
              this.$t("message.$ERR_UNIQ") + " [" + user[field] + "]";
          }
        }
      });

      dateFields.forEach((field) => {
        if (user[field]) {
          // let m = moment(user[field]);
          // console.log(moment(user[field]));
          if (user[field] == "-") user[field] = "";
          if (user[field] && !moment(user[field], "D/M/YYYY").isValid()) {
            valid = false;
            invalidFields.push(field);
            user[field] = this.$t("message.$ERR_INVALID");
          }
        }
      });

      // const genders = ["m", "f", "x"];
      // if (user["gender"] && !genders.includes(user["gender"])) {
      //   valid = false;
      //   invalidFields.push("gender");
      //   user["gender"] =
      //     this.$t("message.$ERR_INVALID") + " [" + user["gender"] + "]";
      // }

      const languages = ["en", "nl-BE", "fr-FR"];
      if (user["language"] && !languages.includes(user["language"])) {
        valid = false;
        invalidFields.push("language");
        user["language"] =
          this.$t("message.$ERR_INVALID") + " [" + user["language"] + "]";
      }

      // Update with listoptions
      //  const educationkeys = this.listOptions.find(
      //    (list) => list.key == "education"
      //  );
      //  const eduoptions = educationkeys.options.map((option) => option.key);
      //  if (user["education"] && !eduoptions.includes(user["education"])) {
      //    valid = false;
      //    invalidFields.push("education");
      //    user["education"] =
      //      this.$t("message.$ERR_INVALID") + " [" + user["education"] + "]";
      //  }

      if (user["country"]) {
        // console.log('countries',countries)
        if (!this.countries.includes(user["country"])) {
          valid = false;
          invalidFields.push("country");
          user["country"] =
            this.$t("message.$ERR_INVALID") + " [" + user["country"] + "]";
        }
      }

      if (user["teams"].length > 0) {
        for (const i in user["teams"]) {
          if (user["teams"][i] == "") {
            user["teams"].splice(i, 1);
          }
        }
      }

      if (user["tags"].length > 0) {
        for (const i in user["tags"]) {
          if (user["tags"][i] == "") {
            user["tags"].splice(i, 1);
          }
        }
      }
      // console.log(user['teams']);

      user.invalidFields = invalidFields;

      return { valid: valid, user: user };
    },
    createOptionsCSV() {
      // Add gender keys to list options
      this.listOptions.push({
        key: "gender",
        options: [
          {
            key: "m",
          },
          {
            key: "f",
          },
          {
            key: "x",
          },
        ],
      });
      // Convert to CSV
      let headers = [this.listOptions.map((heading) => heading.key).join(";")];
      const length = Math.max(
        ...this.listOptions.map((heading) => heading.options.length)
      );

      const rows = [];
      for (let i = 0; i < length; i++) {
        rows.push(
          this.listOptions
            .map((heading) =>
              heading.options[i] ? heading.options[i].key : ""
            )
            .join(";")
        );
      }
      let finalArray = headers.concat(rows);
      let csvContent = "data:text/csv;charset=utf-8,\uFEFF";
      csvContent += finalArray.join("\r\n");

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "vitamins_user_import_keys.csv");
      document.body.appendChild(link);
      link.click();
    },
    parseFile(e) {
      this.closePostProcess();
      const rows = e.target.result.split("\n");
      let users = [];
      this.results = [];
      // let headerColumnLength = 0;
      // console.log("len", headerColumnLength);
      rows.forEach((row, rowIndex) => {
        let empty = true;
        let splitRow = row.split(";");

        this.columns.forEach((column, columnIndex) => {
          if (
            splitRow[columnIndex] &&
            !splitRow[columnIndex].match(
              /^\s+$/
            ) /*this.isEmptyOrSpaces(splitRow[columnIndex])*/ &&
            splitRow[columnIndex] !== "\r"
          ) {
            splitRow[columnIndex] = splitRow[columnIndex]
              .split("\r")
              .join("")
              .trim();
            empty = false;
          } else if (splitRow[columnIndex] == "\r") {
            splitRow[columnIndex] = "";
          }
        });

        if (!empty) {
          // if (rowIndex === 0) {
          //   headerColumnLength = splitRow.length;
          //   // console.log(headerColumnLength)
          // }
          if (rowIndex > 0) {
            //skips the header
            //todo match header to this.columns, now is assumed to be identical and in same order
            let user = {};
            this.columns.forEach((column, columnIndex) => {
              user[column] = splitRow[columnIndex];
            });
            user["gender"] = user["gender"].toLowerCase();
            user["education"] = user["education"].toLowerCase();
            user["transportation"] = user["transportation"].toLowerCase();
            user["maritalstate"] = user["maritalstate"].toLowerCase();
            user["country"] = user["country"].toUpperCase();
            user["teams"] = user["teams"].split("|");
            user["tags"] = user["tags"].split("|");

            let validatedUser = this.validateUser(user, users);

            if (validatedUser.valid) {
              // console.log('user is valid')
              let bd = moment(user.birthdate);
              let es = moment(user.employmentstart);
              let ed = moment(user.employmentend);

              bd = bd.isValid()
                ? bd
                : (bd = moment(user.birthdate, "D/M/YYYY"));
              es = es.isValid()
                ? es
                : (es = moment(user.employmentstart, "D/M/YYYY"));
              ed = ed.isValid()
                ? ed
                : (ed = moment(user.employmentend, "D/M/YYYY"));

              user.birthdate = bd.isValid() ? bd.format("YYYY-MM-DD") : null;
              user.employmentstart = es.isValid()
                ? es.format("YYYY-MM-DD")
                : null;
              user.employmentend = ed.isValid()
                ? ed.format("YYYY-MM-DD")
                : null;
              user.password = this.randomPassword();
              user.fullname = user.firstname + " " + user.lastname;
              user.username = user.email;
              user.rowIndex = rowIndex + 1;
              // console.log('user', user);
              users.push(user);
            } else {
              this.invalidRows.push(validatedUser.user);
            }
          }
        }
      });
      this.results = users;
    },
    resetFileInput() {
      // console.log('input handler')
    },
    readFile(evt, encoding = "utf-8") {
      let files = null;
      // this.filename = '';
      // this.reader = new FileReader();
      // this.reader.onload = (e) => {
      //   this.parseFile(e)
      // };

      files = evt.target.files;
      // console.log('readfile',files)
      this.filename = files[0].name;
      this.file = files[0];
      this.reader.readAsText(this.file, encoding);
      evt.target.value = "";
    },
  },
};
</script>
<style>
@keyframes loadfade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes loadfade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.loadfade-enter-active {
  animation: loadfade-in 0s ease-out forwards;
  animation-delay: 0s;
}

.loadfade-leave-active {
  animation: loadfade-out 0.5s ease-out forwards;
  animation-delay: 1s;
}
</style>
